import * as React from 'react';
import {graphql} from 'gatsby';
import TwoColumnsTwoThirdsOneThird from '../layouts/TwoColumnsTwoThirdsOneThird';
import {Speaker} from '../components/speaker/SpeakersRow';
import SEO from '../components/SEO/SEO';
import SpeakerCard from '../components/speaker/SpeakerCard';
import moment from 'moment';
const { toLaxTitleCase } = require('titlecase');

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerDetailsPageProps {
    data: QueryResult
}

interface QueryResult {
    markdownRemark: {
        html: string;
        frontmatter: {
            title: string;
            startDateTime: Date;
            endDateTime: Date;
            formattedStartDate: string
            formattedEndDate: string
            location: string
            session: string
            speakers: Speaker[];
        }
    }
    site: {
        siteMetadata: {
            organizer: {
                name: string;
                website: string;
            };
            location: {
                name: string
                address: {
                    streetAddress: string;
                    city: string;
                    postalCode: string;
                    country: string;
                }
            }
        }
    }
}

const SpeechDetailsPage: React.FC<SpeakerDetailsPageProps> = ({data}) => {
    const title = data.markdownRemark.frontmatter.title;
    const organizer = data.site.siteMetadata.organizer;
    const location = data.site.siteMetadata.location;

    const startDateTime = moment(data.markdownRemark.frontmatter.startDateTime).subtract(2, 'h');
    const endDateTime = moment(data.markdownRemark.frontmatter.endDateTime).subtract(2, 'h');
    const speakers = data.markdownRemark.frontmatter.speakers;

    const seoDescription = `Discover ESTECO UM20 agenda. Attend the presentation ${title}`;
    const seoTitle = `ESTECO UM20 |  ${title}`;

    const schema = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        'name': title,
        'description': seoDescription,
        'startDate': startDateTime,
        'endDate': endDateTime,
        'eventAttendanceMode': 'https://schema.org/MixedEventAttendanceMode',
        'organizer': {
            '@type': 'Organization',
            'name': organizer.name,
            'url': organizer.website
        },
        'location': [
            {
                '@type': 'VirtualLocation',
                'url': 'https://www.accelevents.com/e/estecoum20/portal/'
            },
            {
                '@type': 'Place',
                'name': location.name,
                'address': {
                    '@type': 'PostalAddress',
                    'streetAddress': location.address.streetAddress,
                    'addressLocality': location.address.city,
                    'postalCode': location.address.postalCode,
                    'addressCountry': location.address.country
                }
            }
        ],
        'performer': [speakers?.map(one => {
            return {
                '@type': 'Person',
                'name': `${one.childMarkdownRemark.frontmatter.firstName} ${one.childMarkdownRemark.frontmatter.lastName}`
            };
        })],
        'eventStatus': 'https://schema.org/EventScheduled'
    };

    return (
        <React.Fragment>
            <TwoColumnsTwoThirdsOneThird>
                <React.Fragment>
                    <SEO title={seoTitle}
                         description={seoDescription}
                         schemaMarkup={schema}
                    />
                    <h1 className="soul-space-stack-top-3-xl h-tx-color--brand-base  soul-font-size-xl  h-text-bold">{toLaxTitleCase(title)}</h1>
                    <p>
                        {data.markdownRemark.frontmatter.formattedStartDate} - {data.markdownRemark.frontmatter.formattedEndDate} CEST
                    </p>

                    <div className="soul-content  soul-content--m  soul-space-stack-top-s"
                         dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
                    <div className="soul-space-stack-bottom-l"></div>
                </React.Fragment>

                <React.Fragment>
                    <h2 className="soul-space-stack-top-3-xl soul-font-size-xl h-text-bold">
                        Speaker{data.markdownRemark.frontmatter.speakers.length > 1 ? 's' : ''}
                    </h2>
                    <div className="soul-space-stack-bottom-l"/>
                    {speakers.map(speaker =>
                        <div className="soul-space-stack-bottom-l" key={speaker.id}>
                            <SpeakerCard relativePath={speaker.relativePath} speaker={speaker.childMarkdownRemark.frontmatter}/>

                        </div>)
                    }
                </React.Fragment>
            </TwoColumnsTwoThirdsOneThird>
        </React.Fragment>
    );
};

export const query = graphql`
    query RetrieveSpeech($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                startDateTime
                endDateTime
                formattedStartDate: startDateTime(formatString: "DD MMMM, HH:mm")
                formattedEndDate: endDateTime(formatString: "HH:mm")
                location
                session
                speakers {
                    id
                    relativePath
                    childMarkdownRemark {
                        frontmatter {
                            firstName
                            lastName
                            avatar {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            jobTitle
                            organization
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                organizer {
                    name
                    website
                }
                location {
                    name
                    address {
                        streetAddress
                        city
                        postalCode
                        country
                    }
                }
            }
        }
    }
`;

export default SpeechDetailsPage;
